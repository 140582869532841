import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';

import { getBanners } from './api';
import { BannerData, BannersFilter } from './types';

export const useBanners = (data: CommonSearchParams<Partial<BannersFilter>>) =>
    useBaseQuery<CommonSearchParams<Partial<BannersFilter>>, CommonResponse<BannerData[]>, FetchError>({
        data,
        getMethod: getBanners,
    });
