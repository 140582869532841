import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { dehydrate } from 'react-query';
import { useCityStore } from 'src/store';

import { updateAuthInfo, useLoginHelper } from '@api/auth/helpers';
import { getCategories } from '@api/catalog/categories/api';
import { getProducts } from '@api/catalog/product/api';
import { getPagination } from '@api/common/helpers';
import { getBanners } from '@api/content/api';
import { useBanners } from '@api/pages';
import { getSeoTags } from '@api/seo/api';

import { useCartContext } from '@context/cart';
import { useSeoContext } from '@context/seo';

import { ExpandableBlock } from '@components/ExpandableBlock';
import PageWrapper from '@components/PageWrapper';
import ProductCard from '@components/ProductCard';
import ProductCarousel from '@components/ProductCarousel';
import Carousel from '@components/controls/Carousel';
import Picture from '@components/controls/future/Picture';

import { BannerType } from '@scripts/enums/banners';
import { Layout, Section, colors, scale } from '@scripts/gds';
import { transformImageUrl } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';
import { useGetProductsList } from '@scripts/hooks/useGetProductsList';
import { useGetUserData } from '@scripts/hooks/useGetUserData';
import { getCommonProps } from '@scripts/server/getCommonProps';
import { ISSRPageProps } from '@scripts/server/types';

import CarouselItem from './components/CarouselItem';
import MainBanners from './components/MainBanners';
import PopularCategoriesCarousel from './components/PopularCategoriesCarousel';
import { featuresButtons, searchParams } from './helper';
import { linkStyles } from './styles';

export default function Home() {
    const { query } = useRouter();

    const { loginByToken } = useLoginHelper();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const { basketProducts } = useCartContext();
    const { smMin, sm, xxxsMin, lgMin, xlMin, xxlMin } = useMedia();

    const { apiUser, error: userError, user } = useGetUserData();

    const { productsList: profitableProductsListData } = useGetProductsList(
        {
            ...searchParams,
            filter: { is_profitable: true },
        },
        { enabled: user === '', enabledAuth: Boolean(apiUser) && !userError }
    );

    const { productsList: newProductsListData } = useGetProductsList(
        {
            ...searchParams,
            sort: '-created_at',
        },
        { enabled: user === '', enabledAuth: Boolean(apiUser) && !userError }
    );

    const { data: category } = useBanners({
        filter: { type: BannerType.PROMO, is_on_main_page: true },
        sort: 'display_order',
    });
    const categoryBanner = useMemo(() => category?.data[0], [category]);

    const { productsList: categoryProductsListData } = useGetProductsList(
        {
            ...searchParams,
            filter: { category_id: categoryBanner?.category_id },
        },
        {
            enabled: Boolean(user === '' && categoryBanner?.category_id),
            enabledAuth: Boolean(apiUser && !userError && categoryBanner?.category_id),
        }
    );

    const { data: big } = useBanners({
        filter: { type: BannerType.BIG, is_on_main_page: true },
        sort: 'display_order',
    });

    const bigBanner = useMemo(() => big?.data[0], [big]);

    const seoContexData = useSeoContext();

    useEffect(() => {
        if (query.access_token) {
            const res = async () => {
                await loginByToken(
                    { token: (query.access_token as string) || '' },
                    setIsLoading,
                    currentCity.region_guid,
                    basketProducts
                );
                await updateAuthInfo({
                    data: {
                        access_token: (query.access_token as string) || '',
                        refresh_token: '',
                        expires_in: 86400,
                    },
                });
            };

            res();

            window.history.pushState({}, document.title, '/');
        }
    }, [query]);

    return (
        <PageWrapper>
            <Section>
                <Layout cols={{ xxxl: 12, sm: 7 }} gap={{ xxxl: scale(8), sm: scale(5) }}>
                    <Layout.Item
                        col={{ xxxl: 12, sm: 7 }}
                        css={{
                            backgroundColor: colors.backgroundMain,
                            display: 'flex',
                            gap: scale(5),
                            flexDirection: 'column',
                            [xxxsMin]: {
                                margin: `0 -${scale(3, true)}px`,
                                padding: `${scale(3)}px ${scale(3, true)}px`,
                            },
                            [smMin]: {
                                margin: `0 -${scale(4)}px`,
                                padding: `${scale(3)}px ${scale(4)}px ${scale(4)}px`,
                            },
                            [lgMin]: {
                                margin: `0 -${scale(3)}px`,
                                padding: `${scale(3)}px  ${scale(3)}px ${scale(4)}px`,
                            },
                            [xlMin]: {
                                margin: `0 -${scale(9)}px`,
                                padding: `${scale(3)}px  ${scale(9)}px ${scale(4)}px`,
                            },
                            [xxlMin]: {
                                margin: 0,
                                padding: `${scale(3)}px  ${scale(10)}px ${scale(4)}px `,
                            },
                        }}
                    >
                        <MainBanners />

                        <div
                            css={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: scale(3),
                                '.swiper-slide': {
                                    marginRight: '16px !important',
                                    width: '306px !important',
                                },
                                [sm]: {
                                    '.swiper-slide': {
                                        width: '284px !important',
                                    },
                                },
                            }}
                        >
                            <Carousel css={{ width: '100%' }} type="options">
                                {featuresButtons.map((item, index) => (
                                    <Carousel.Item key={item.id}>
                                        <CarouselItem item={item} idx={index} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%', height: '547px' }}>
                        <ProductCarousel
                            products={profitableProductsListData}
                            title="Выгодное предложение"
                            link={`/catalog/search?filters=%7B"is_profitable"%3A%5B"true"%5D%7D`}
                            lazy
                        />
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%', height: '547px' }}>
                        <ProductCarousel
                            products={newProductsListData}
                            title="Новинки"
                            link="/catalog/search?is_new=true"
                            lazy
                        />
                    </Layout.Item>

                    {bigBanner?.url && (
                        <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%', position: 'relative' }}>
                            <Link legacyBehavior href={bigBanner.url} passHref prefetch={false}>
                                <a css={{ width: '100%' }} aria-label={bigBanner.name}>
                                    <Picture
                                        sources={[
                                            {
                                                media: '(max-width: 567px)',
                                                image: transformImageUrl(bigBanner.mobile_image, 0, scale(40), 'we'),
                                            },
                                            {
                                                media: '(max-width: 767px)',
                                                image: transformImageUrl(bigBanner.tablet_image, 0, scale(40), 'we'),
                                            },
                                            {
                                                media: '(min-width: 768px)',
                                                image: transformImageUrl(bigBanner.desktop_image, 0, scale(40), 'we'),
                                            },
                                        ]}
                                        loading="lazy"
                                        placeholder="blur"
                                        alt=""
                                        width="auto"
                                        height="auto"
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'left',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: scale(1),
                                            maxHeight: scale(40),
                                        }}
                                    />
                                </a>
                            </Link>
                        </Layout.Item>
                    )}

                    <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%' }}>
                        <PopularCategoriesCarousel />
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }}>
                        {categoryProductsListData && categoryBanner?.category_id && (
                            <Layout cols={{ xxxl: 6, md: 5, xs: 6 }}>
                                <Layout.Item
                                    col={{ xxxl: 2, md: 2, xs: 6 }}
                                    css={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                        [sm]: { height: scale(40) },
                                    }}
                                >
                                    {categoryBanner && (
                                        <Picture
                                            sources={[
                                                {
                                                    media: '(max-width: 567px)',
                                                    image: transformImageUrl(
                                                        categoryBanner.mobile_image,
                                                        0,
                                                        scale(40),
                                                        'we'
                                                    ),
                                                },
                                                {
                                                    media: '(max-width: 767px)',
                                                    image: transformImageUrl(
                                                        categoryBanner.tablet_image,
                                                        0,
                                                        scale(40),
                                                        'we'
                                                    ),
                                                },
                                                {
                                                    media: '(min-width: 768px)',
                                                    image: transformImageUrl(
                                                        categoryBanner.desktop_image,
                                                        0,
                                                        scale(54),
                                                        'we'
                                                    ),
                                                },
                                            ]}
                                            loading="lazy"
                                            placeholder="blur"
                                            alt={categoryBanner.name}
                                            width="auto"
                                            height="auto"
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'left center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    )}

                                    {categoryBanner?.url && categoryBanner?.button?.text && (
                                        <Link legacyBehavior href={categoryBanner.url} passHref prefetch={false}>
                                            <a
                                                aria-label={categoryBanner.button.text}
                                                css={{
                                                    ...linkStyles,
                                                    zIndex: 2,
                                                    color: colors.primary,
                                                    border: '1px solid transparent',
                                                }}
                                            >
                                                {categoryBanner.button.text}
                                            </a>
                                        </Link>
                                    )}
                                </Layout.Item>

                                <Layout.Item col={{ xxxl: 4, md: 3, xs: 6 }} css={{ width: '100%' }}>
                                    <Carousel
                                        spaceBetween={scale(3)}
                                        slidesPerView={4}
                                        css={{ width: '100%', paddingBottom: scale(1) }}
                                        type="category"
                                        lazy
                                    >
                                        {categoryProductsListData.map(item => (
                                            <Carousel.Item key={item.id}>
                                                <ProductCard isSlider productItem={item} />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Layout.Item>
                            </Layout>
                        )}
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }}>
                        {seoContexData.seo_text && (
                            <ExpandableBlock height={scale(15)}>
                                <p
                                    css={{ marginTop: scale(6) }}
                                    dangerouslySetInnerHTML={{ __html: seoContexData.seo_text }}
                                />
                            </ExpandableBlock>
                        )}
                    </Layout.Item>
                </Layout>
            </Section>
        </PageWrapper>
    );
}

export const getServerSideProps = async (
    context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<ISSRPageProps>> =>
    getCommonProps(context, async (props, queryClient) => {
        if (props.isSSR) {
            const { key: baseProductsProfitKey, fetch: baseProductsProfitFetch } = getProducts({
                ...searchParams,
                filter: { is_profitable: true },
                include: ['brand', 'category', 'images', 'attributes', 'seller', 'discounts'],
            });
            const { key: popularCategoriesKey, fetch: popularCategoriesFetch } = getCategories({
                pagination: getPagination(12),
                filter: { is_active: true, is_real_active: true, is_promo: true },
            });
            const { key: baseProductsNewKey, fetch: baseProductsNewFetch } = getProducts({
                ...searchParams,
                sort: '-created_at',
            });
            const { key: sliderKey, fetch: sliderFetch } = getBanners({
                filter: { type: BannerType.SLIDER, is_on_main_page: true },
                pagination: getPagination(-1),
            });
            const { key: rightBannerKey, fetch: rightBannerFetch } = getBanners({
                filter: { type: BannerType.SQUARE, is_on_main_page: true },
            });
            const { key: bigBannerKey, fetch: bigBannerFetch } = getBanners({
                filter: { type: BannerType.BIG, is_on_main_page: true },
            });
            const { key: categoryBannerKey, fetch: categoryBannerFetch } = getBanners({
                filter: { type: BannerType.PROMO, is_on_main_page: true },
            });

            const categoryBanner = await queryClient.fetchQuery({
                queryKey: categoryBannerKey,
                queryFn: categoryBannerFetch,
            });

            if (categoryBanner.data[0]?.category_id) {
                const { key: baseProductsCategoryKey, fetch: baseProductsCategoryFetch } = getProducts({
                    ...searchParams,
                    filter: { category_id: categoryBanner.data[0].category_id },
                });
                await queryClient.prefetchQuery(baseProductsCategoryKey, baseProductsCategoryFetch);
            }

            const { key: seoTagsKey, fetch: baseSeoTagsFetch } = getSeoTags({
                filter: {
                    url: '/',
                    type: 'main_page',
                },
            });
            await Promise.all([
                queryClient.prefetchQuery(baseProductsProfitKey, baseProductsProfitFetch),
                queryClient.prefetchQuery(baseProductsNewKey, baseProductsNewFetch),
                queryClient.prefetchQuery(seoTagsKey, baseSeoTagsFetch),
                queryClient.prefetchQuery(sliderKey, sliderFetch),
                queryClient.prefetchQuery(rightBannerKey, rightBannerFetch),
                queryClient.prefetchQuery(bigBannerKey, bigBannerFetch),
                queryClient.prefetchQuery(popularCategoriesKey, popularCategoriesFetch),
            ]);
        }
        return {
            props: {
                ...props,
                dehydratedState: dehydrate(queryClient),
            },
        };
    });
